<template>
  <!-- 下载卡片-组件 -->
  <div class="download-card">
    <!-- 下载标题 -->
    <p v-text="title" class="download-title" />

    <!-- 文件卡片集合 -->
    <template>
      <div
        class="card-container"
        v-for="(item, index) in filesData"
        :key="index"
      >
        <p class="container-title" v-text="item.title" />

        <div class="cards">
          <div
            class="card-item"
            v-for="(file, fileIdx) in item.files"
            :key="fileIdx"
          >
            <!-- 正常 -->
            <div class="card-normal">
              <img src="/icons/icon_PDF2.png" alt="" />
              <p class="card-name" v-text="file.name" />
              <p>下载资料了解更多</p>
            </div>

            <!-- hover状态 -->
            <div class="card-hover">
              <p class="card-fileName" v-text="file.fileName" />
              <p v-text="file.memo" />
              <el-button
                type="primary"
                class="card-downBtn"
                @click="$emit('downloadFile', file)"
                >点击下载</el-button
              >
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "applyContact",
  props: {
    title: {
      type: String,
      default: "您可以在此下载白皮书及其他资源,来了解挖掘互联数据的价值",
    },
    filesData: {
      type: Array,
      default() {
        return [
          {
            title: "标题",
            files: [
              {
                id: "文件id",
                name: "文件简介名",
                fileName: "文件全名",
                memo: "文件备注",
              },
            ],
          },
        ];
      },
    }, // 文件
  },
};
</script>

<style lang="less" scoped>
.download-card {
  width: 1200px;
  padding: 0 30px;
  margin: 30px auto 0;

  .download-title {
    font-size: 14px;
    color: var(--main-memo_gray);
  }

  .card-container {
    margin-top: 30px;

    .container-title {
      width: 200px;
      line-height: 40px;
      margin: 0 auto 20px;
      background-color: #1890ff;
    }

    .cards {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      .card-item {
        width: 360px;
        height: 240px;
        font-size: 14px;
        position: relative;
        color: var(--main-memo_gray);
        margin-bottom: 30px;

        &:nth-last-child(-n + 3) {
          margin-bottom: 0;
        }

        &:hover {
          .card-normal {
            opacity: 0;
          }

          .card-hover {
            opacity: 1;
          }
        }

        .card-normal {
          position: absolute;
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          border-radius: 2px;
          box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
          transition: all 0.3s;
          opacity: 1;

          .card-name {
            font-size: 16px;
            font-weight: bold;
            margin: 30px 0 20px;
            color: var(--main-title_black);
          }
        }

        .card-hover {
          position: absolute;
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          border-radius: 2px;
          background-color: #f7f8f8;
          box-shadow: 0 2px 6px 3px rgba(0, 0, 0, 0.12),
            0 0 6px rgba(0, 0, 0, 0.04);
          transition: all 0.3s;
          opacity: 0;

          .card-fileName {
            font-size: 16px;
            margin-bottom: 30px;
            color: var(--main-title_black);
          }

          .card-downBtn {
            width: 80px;
            padding: 0;
            margin-top: 20px;
            line-height: 32px;
            text-align: center;
          }
        }
      }
    }
  }
}
</style>
