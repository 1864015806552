<template>
  <!-- 资料中心 -->
  <div>
    <!-- 产品介绍 -->
    <product-brief
      height="340"
      :title="currentData && currentData.productBrief.title"
      :memo="currentData && currentData.productBrief.memo"
      :img-src="currentData && currentData.productBrief.imgSrc"
    />

    <!-- 产品详细介绍-横幅 -->
    <horizontal-bar
      v-for="(item, index) in currentData && currentData.horizontalBars"
      :key="index"
      :grayTitle="item.grayTitle"
      :title="item.title"
      :subTitle="item.subTitle"
      :barStyle="item.style"
    >
      <!-- 文档下载 -->
      <download-card
        :filesData="item.downloadCard"
        @downloadFile="downloadFile"
      />
    </horizontal-bar>
  </div>
</template>

<script>
import downloadCard from "./components/download-card";

export default {
  components: {
    downloadCard, // 下载卡片组件
  },
  data() {
    return {
      currentData: {
        productBrief: {
          title: "资料分享",
          memo: ["共享资源,了解挖掘互联数据的价值"],
          imgSrc: "https://s1.ax1x.com/2020/07/27/aPruqg.png",
        }, // 产品介绍组件数据
        horizontalBars: [
          {
            grayTitle: true,
            title: "资料下载中心",
            subTitle: "Data Download Center",
            downloadCard: [
              {
                title: "开放平台",
                files: [
                  {
                    id: "1",
                    name: "广州瑞友公司简介",
                    fileName: "广州瑞友公司简介.docx",
                    memo: "案例研究",
                    filePath: "/files/关于瑞友.docx",
                  },
                  {
                    id: "1",
                    name: "微服务架构平台",
                    fileName: "微服务架构平台.pdf",
                    memo: "案例研究",
                  },
                  {
                    id: "1",
                    name: "知识图谱平台",
                    fileName: "知识图谱平台.pdf",
                    memo: "案例研究",
                  },
                ],
              },
              {
                title: "产品与解决方案",
                files: [
                  {
                    id: "1",
                    name: "AI+金融应用场景",
                    fileName: "AI+金融应用场景.pdf",
                    memo: "案例研究",
                  },
                  {
                    id: "1",
                    name: "基于知识图谱的反欺诈系统",
                    fileName: "基于知识图谱的反欺诈系统.pdf",
                    memo: "案例研究",
                  },
                  {
                    id: "1",
                    name: "智能问答机器人",
                    fileName: "智能问答机器人.pdf",
                    memo: "案例研究",
                  },
                  {
                    id: "1",
                    name: "机器人流程自动化（RPA）",
                    fileName: "机器人流程自动化（RPA）.pdf",
                    memo: "案例研究",
                  },
                  {
                    id: "1",
                    name: "大数据可视化平台（iDataR)",
                    fileName: "大数据可视化平台（iDataR).pdf",
                    memo: "案例研究",
                  },
                  {
                    id: "1",
                    name: "图数据库与产品选型介绍",
                    fileName: "图数据库与产品选型介绍.pdf",
                    memo: "案例研究",
                  },
                ],
              },
              {
                title: "白皮书",
                files: [
                  {
                    id: "1",
                    name: "新一代银行产品定价系统",
                    fileName: "新一代银行产品定价系统.pdf",
                    memo: "案例研究",
                  },
                  {
                    id: "1",
                    name: "新一代资产负债管理（ALM）",
                    fileName: "新一代资产负债管理（ALM）.pdf",
                    memo: "案例研究",
                  },
                  {
                    id: "1",
                    name: "智能采购管理系统",
                    fileName: "智能采购管理系统.pdf",
                    memo: "案例研究",
                  },
                  {
                    id: "1",
                    name: "新一代内部资金转移定价系统（FTP）",
                    fileName: "新一代内部资金转移定价系统（FTP）.pdf",
                    memo: "案例研究",
                  },
                  {
                    id: "1",
                    name: "FVR金融仿真实验教学系统",
                    fileName: "FVR金融仿真实验教学系统.pdf",
                    memo: "案例研究",
                  },
                  {
                    id: "1",
                    name: "新一代管理会计建设白皮书",
                    fileName: "新一代管理会计建设白皮书.pdf",
                    memo: "案例研究",
                  },
                ],
              },
            ],
          },
        ], // 横幅内容
      },
    };
  },
  created() {},
  methods: {
    downloadFile(file) {
      if (!this.$store.state.isLogin) {
        this.$store.commit("changeShowLogin", true);
      }
      // 带文件路径
      else if (file.filePath) {
        let link = document.createElement("a");
        link.style.display = "none";
        link.href = file.filePath;
        link.setAttribute("download", file.fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
      // 申请试用
      else {
        this.$message({
          type: "warning",
          message: "请填写申请试用以获取相关资料",
        });
        this.$router.push({
          path: "/applyTrial",
          query: { fileName: file.fileName },
        });
      }
    },
  },
};
</script>

<style></style>
